import { useEffect, useLayoutEffect, useRef, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"

export function useMeasure() {
  const ref = useRef()
  const [bounds, set] = useState({
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  })
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  )
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ro])
  return [{ ref }, bounds]
}

export function useWindowSize() {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) return false

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener(`resize`, handleResize)
    return () => window.removeEventListener(`resize`, handleResize)
  }, [])
  return windowSize
}

export function useThrottle(func, ms, leading = false, changes = []) {
  const timer = useRef(null)

  function cancel() {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }
  }

  useEffect(() => cancel, changes)

  return function (...args) {
    if (!timer.current && leading) func.apply(this, args)

    cancel()

    timer.current = setTimeout(() => {
      timer.current = null
      func.apply(this, args)
    }, ms)
  }
}

export function useAnimationFrame(func, throttle) {
  const isRunning = useRef(null)
  const isClient = typeof window === "object"

  if (!isClient) return false

  return function (...args) {
    if (isRunning.current && throttle) return

    isRunning.current = true
    window.requestAnimationFrame(() => {
      isRunning.current = false
      func.apply(this, args)
    })
  }
}
