import React from "react"
import styled from "@emotion/styled"
import FacebookIcon from "assets/icons/facebook.svg"
import LinkedinIcon from "assets/icons/linkedin.svg"
import { CONTAINER_FONT } from "static/global-css"

const SocialLinks = ({ ...rest }) => (
  <Wrapper {...rest}>
    <a
      href="https://www.facebook.com/christian.zwergius/"
      alt="Facebook"
      target="_blank"
      rel="noopener noreferrer"
      title="Facebook"
    >
      <FacebookIcon />
    </a>
    <a
      href="https://www.linkedin.com/in/christian-zwergius/"
      alt="LinkedIn"
      target="_blank"
      rel="noopener noreferrer"
      title="LinkedIn"
    >
      <LinkedinIcon />
    </a>
  </Wrapper>
)

export default SocialLinks

const Wrapper = styled.div({
  display: `flex`,
  "> a": {
    borderBottom: `2px solid transparent`,
    marginLeft: 20,
    ":hover": {
      cursor: `pointer`,
      filter: `brightness(200%)`,
      borderBottom: `2px solid ${CONTAINER_FONT}`,
    },
    "> svg": {
      fill: `${CONTAINER_FONT}`,
      width: 20,
      height: 20,
      margin: `auto`,
    },
  },
})
