import { css } from "@emotion/core"
import Poly from "assets/fonts/Poly-Regular.otf"
export const HEADER_BG = `rgba(51, 51, 51, 0.9)`
export const NAVBAR_BG = `rgb(51, 51, 51)`
export const NAV_ACTIVE_BG = `rgb(0, 0, 0)`
export const CONTAINER_BG = `rgb(36, 36, 36)`
export const CONTENT_BG = `rgba(36, 36, 36, 0.95)`
export const BUTTON_BG = `rgb(212, 212, 212)`
export const HEADER_FONT = `rgb(145, 145, 145)`
export const CONTAINER_FONT = `rgb(212, 212, 212)`
export const NAV_ACTIVE_FONT = `rgb(234, 234, 234)`
export const HIGHLIGHT_FONT = `rgb(250, 215, 115)`
export const HEADER_BORDER = `rgb(82, 82, 82)`

export default css`
  @font-face {
    font-family: "Poly";
    src: url(${Poly}) format("opentype");
  }

  * {
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  html {
    height: -webkit-fill-available;
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    font-family: "IBM Plex Sans", "Helvetica Neue", Arial, sans-serif;
    font-weight: normal;
    color: ${CONTAINER_FONT};
    background-color: ${CONTAINER_BG};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-family: "Poly";
    font-weight: bold;
    color: ${HIGHLIGHT_FONT};
    font-size: 28px;
  }

  h1,
  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 22px;
  }

  p,
  span {
    margin: 0;
    line-height: 1.5rem;
  }

  button {
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
    border: none;
  }

  :root {
    font-size: 16px;
  }

  [disabled] {
    opacity: 0.7;
    pointer-events: none;
  }

  [readonly] {
    cursor: default;
  }

  ::placeholder {
  }
`
