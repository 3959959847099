import React from "react"
import styled from "@emotion/styled"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Icon from "assets/icons/sosa/$Eacute_Sosa-Regular.svg"
import { CONTAINER_FONT } from "static/global-css"

const MessageMe = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contactEmail
        }
      }
    }
  `)

  function handleClick(e) {
    window.location.href = `mailto:${site.siteMetadata.contactEmail}`
  }

  return (
    <Button type="button" onClick={handleClick}>
      <Icon />
      <FormattedMessage id="button.messageMe" />
    </Button>
  )
}

export default MessageMe

const Button = styled.button({
  fontFamily: `'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace`,
  border: `1px solid ${CONTAINER_FONT}`,
  background: `none`,
  borderRadius: 30,
  display: `flex`,
  alignItems: `center`,
  fontSize: `1rem`,
  padding: `0.2rem 1rem`,
  transition: "all 0.15s ease-in-out",
  ":hover": {
    cursor: `pointer`,
    filter: `brightness(200%)`,
  },
  "> svg": {
    height: `1.7rem`,
    width: `1.7rem`,
    margin: 0,
    marginRight: `.5rem`,
    marginBottom: 2,
  },
})
