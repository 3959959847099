import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { image } = useStaticQuery(graphql`
    query ogImage {
      image: file(relativePath: { eq: "laptop.jpg" }) {
        childImageSharp {
          fixed(width: 1200, height: 630) {
            src
          }
        }
      }
    }
  `)
  const intl = useIntl()
  const metaAuthor = intl.formatMessage({ id: `meta.author` })
  const metaDescription = description || intl.formatMessage({ id: `meta.desc` })
  const baseTitle = intl.formatMessage({ id: `meta.title` })
  const metaTitle = title ? `${baseTitle} | ${title}` : baseTitle

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={[
        {
          name: `title`,
          content: metaTitle,
        },
        {
          name: `author`,
          content: metaAuthor,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image.childImageSharp.fixed.src,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
