import React, { useRef, useState } from "react"
import { node, func } from "prop-types"
import styled from "@emotion/styled"
import { Global } from "@emotion/core"
import { useStaticQuery, graphql } from "gatsby"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useAnimationFrame } from "helpers/hooks"
import { mqTablet } from "static/constants"
import globalCSS from "static/global-css"
import SL from "components/social-links"
import Header from "./header"

const Layout = ({ children, getExcludedTarget, ...rest }) => {
  const [didScrollUp, setDidScrollUp] = useState(true)
  const prevScroll = useRef(0)

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          contactEmail
        }
      }
    }
  `)

  function handleClick(e) {
    window.location.href = `mailto:${site.siteMetadata.contactEmail}`
  }

  function didScroll(scrollUp) {
    setDidScrollUp(scrollUp)
  }

  const toggleHeader = useAnimationFrame(didScroll, true)

  function handleScroll(e) {
    if (e.target === getExcludedTarget()) return
    const { scrollTop } = e.target
    if (scrollTop <= 0) {
      toggleHeader(true)
    } else if (prevScroll.current < scrollTop && didScrollUp) {
      toggleHeader(false)
    } else if (prevScroll.current > scrollTop && !didScrollUp) {
      toggleHeader(true)
    }
    prevScroll.current = scrollTop
  }

  return (
    <>
      <Global styles={globalCSS} />
      <Header isVisible={didScrollUp} />
      <Main {...rest} onScroll={handleScroll}>
        {children}
      </Main>
      <Footer>
        <FormattedMessage
          id="footer"
          values={{
            date: new Date().getFullYear(),
            heart: <Heart>{`<3`}</Heart>,
          }}
        />
        <ContactMe onClick={handleClick}>
          <FormattedMessage id="footer.link" />
        </ContactMe>
        <SocialLinks />
      </Footer>
    </>
  )
}

Layout.defaultProps = {
  getExcludedTarget: () => null,
}

Layout.propTypes = {
  children: node.isRequired,
  getExcludedTarget: func,
}

export default Layout

const Main = styled.main({
  height: `100vh`,
  overflow: `auto`,
  maskImage: `linear-gradient(to bottom, black 97vh, transparent 100vh)`,
  transform: `translateZ(0)`,
  [mqTablet]: {
    paddingBottom: 70,
    overflow: `unset`,
    maskImage: `unset`,
  },
})

const Footer = styled.footer({
  fontFamily: `'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace`,
  position: `fixed`,
  left: 0,
  right: 0,
  bottom: 0,
  display: `flex`,
  justifyContent: `center`,
  alignItems: `center`,
  [mqTablet]: {
    display: `none`,
  },
})

const Heart = styled.span({
  transform: `rotate(270deg)`,
  padding: `0 0 2px 2px`,
  letterSpacing: -2,
})

const ContactMe = styled.button({
  padding: 0,
  fontSize: `inherit`,
  background: `none`,
  border: `none`,
  color: `inherit`,
  marginLeft: 5,
  ":hover": {
    textDecoration: `underline`,
    cursor: `pointer`,
  },
})

const SocialLinks = styled(SL)({
  "> a": {
    marginLeft: 10,
    "> svg": {
      width: 12,
      height: 12,
    },
  },
})
