import React from "react"
import { bool } from "prop-types"
import styled from "@emotion/styled"
import { a, config, useSpring } from "react-spring"
import { useStaticQuery, graphql } from "gatsby"
import { Link, FormattedMessage } from "gatsby-plugin-intl"
import GatsbyImg from "gatsby-image"
import { useMeasure } from "helpers/hooks"
import { mqTablet, navBarMobileHeight } from "static/constants"
import {
  HEADER_BG,
  HEADER_BORDER,
  HEADER_FONT,
  NAV_ACTIVE_BG,
  NAVBAR_BG,
  NAV_ACTIVE_FONT,
} from "static/global-css"
import HomeIcon from "assets/icons/sosa/$Ccedilla_Sosa-Regular.svg"
import CVIcon from "assets/icons/sosa/$D_Sosa-Regular.svg"
import WorkIcon from "assets/icons/sosa/$V_Sosa-Regular.svg"
import MessageMe from "components/message-me"
import SocialLinks from "components/social-links"

const Header = ({ isVisible }) => {
  const [bind, { height }] = useMeasure()
  const { avatar } = useStaticQuery(graphql`
    query HeaderAvatarQuery {
      avatar: file(relativePath: { eq: "zwergius.png" }) {
        childImageSharp {
          fixed(width: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const props = useSpring({
    opacity: isVisible ? 1 : 0,
    transform: `translate3d(0, ${isVisible ? 0 : -height}px, 0)`,
  })

  return (
    <Container style={props}>
      <Row {...bind}>
        <Me>
          <Img fixed={avatar.childImageSharp.fixed} />
          <MessageMe />
          <SocialLinks />
        </Me>
        <Navigation>
          <NavLink activeClassName="active" to="/">
            <HomeIcon />
            <span>
              <FormattedMessage id="nav.home" />
            </span>
          </NavLink>
          <NavLink activeClassName="active" to="/work">
            <WorkIcon />
            <span>
              <FormattedMessage id="nav.work" />
            </span>
          </NavLink>
          <NavLink activeClassName="active" to="/curriculum-vitae">
            <CVIcon />
            <span>
              <FormattedMessage id="nav.cv" />
            </span>
          </NavLink>
          {/*
      <NavLink activeClassName="active" to="/notes">
        <FormattedMessage id="nav.notes" />
      </NavLink>
      */}
        </Navigation>
      </Row>
    </Container>
  )
}

Header.defaultProps = {
  isVisible: true,
}

Header.propTypes = {
  isVisible: bool,
}

export default Header

const Container = styled(a.header)({
  position: `fixed`,
  left: 0,
  top: 0,
  right: 0,
  width: `100vw`,
  background: `${HEADER_BG}`,
  borderBottom: `1px solid ${HEADER_BORDER}`,
  zIndex: 100,
  [mqTablet]: {
    display: `block`,
    top: `unset`,
    bottom: 0,
    background: `${NAVBAR_BG}`,
    border: `none`,
    borderTop: `1px solid ${HEADER_BORDER}`,
    height: navBarMobileHeight,
    paddingBottom: 20,
  },
})

const Row = styled.div({
  display: `flex`,
  justifyContent: `space-between`,
})

const Me = styled.div({
  display: `flex`,
  alignItems: `center`,
  margin: `20px 10px`,
  [mqTablet]: {
    display: `none`,
  },
})

const Img = styled(GatsbyImg)({
  borderRadius: `50%`,
  marginRight: 20,
})

const Navigation = styled.nav({
  display: `flex`,
  fontSize: 28,
  lineHeight: `3rem`,
  [mqTablet]: {
    flex: 1,
    fontSize: 12,
    lineHeight: `normal`,
    justifyContent: `space-evenly`,
    "&.active": {
      color: `${NAV_ACTIVE_FONT}`,
    },
  },
})

const NavLink = styled(Link)({
  display: `flex`,
  justifyContent: `center`,
  flexDirection: `column`,
  textDecoration: `none`,
  color: `${HEADER_FONT}`,
  textAlign: `center`,
  marginRight: `1rem`,
  padding: `0 1rem`,
  [mqTablet]: {
    padding: `.5rem`,
    margin: 0,
    flex: 1,
  },
  "&.active": {
    color: `${NAV_ACTIVE_FONT}`,
    background: `${NAV_ACTIVE_BG}`,
    [mqTablet]: {
      background: `none`,
    },
  },
  "> svg": {
    display: `none`,
    [mqTablet]: {
      display: `block`,
      margin: `auto`,
      height: 25,
    },
  },
})
